import Vue from "vue";
import VueRouter from "vue-router";
//import { component } from "vue/types/umd";

Vue.use(VueRouter);

const routes = [
  // default
  {
    path: "/",
    component: () => import("@/layouts/Default"),
    children: [
      {
        path: "",
        name: "LogInRedirect",
        redirect: { name: "LogIn" },
      },
      {
        path: "auth/log-in",
        name: "LogIn",
        component: () => import("@/views/public/LogInView.vue"),
      },
    ],
  },

  // dashboard
  {
    path: "/dashboard/",
    component: () => import("@/layouts/Dashboard"),
    children: [
      {
        path: "Home",
        name: "Home",
        component: () => import("@/views/public/HomeView.vue"),
      },
      // ClaimTables
      {
        path: "fao/claim",
        name: "ClaimTables",
        component: () => import("@/views/private/tables/ClaimTables.vue"),
      },

      {
        path: "fao/searched-claim",
        name: "SearchedClaimsTable",
        component: () =>
          import("@/views/private/tables/SearchedClaimsTable.vue"),
      },

      // ClaimsDetails
      {
        path: "fao/claims-details",
        name: "ClaimsDetails",
        component: () =>
          import("@/views/private/claimDetails/ClaimsDetails.vue"),
      },

      {
        path: "fao/searched-claim-details",
        name: "SearchedClaimDetails",
        component: () =>
          import("@/views/private/claimDetails/SearchedClaimDetails.vue"),
      },

      // ClaimsDetails
      {
        path: "fao/sms-document",
        name: "SMSDocument",
        component: () => import("@/views/private/documents/SMSDocument.vue"),
      },

      {
        path: "fao/add-new-claim",
        name: "FAOAddNewClaim",
        component: () => import("@/views/private/supervisor/AddNewClaim.vue"),
      },

      {
        path: "fao/add-member",
        name: "MemberPage",
        component: () => import("@/views/private/supervisor/AddMember.vue"),
      },

      // Supervisor
      // SuperClaimView
      {
        path: "superadmin/claim-list",
        name: "SuperClaimView",
        component: () =>
          import("@/views/private/supervisor/SuperClaimView.vue"),
      },

      //SuperClaimDetailsView
      {
        path: "superadmin/claim-details",
        name: "SuperClaimDetailsView",
        component: () =>
          import("@/views/private/supervisor/SuperClaimDetails.vue"),
      },
      {
        path: "superadmin/addworkflow",
        name: "AddWorkFlow",
        component: () => import("@/views/private/supervisor/AddWorkFlow.vue"),
      },
      {
        path: "superadmin/createuser",
        name: "CreateUser",
        component: () => import("@/views/private/supervisor/CreateUser.vue"),
      },
      {
        path: "superadmin/updatemember",
        name: "UpdateMember",
        component: () => import("@/views/private/supervisor/UpdateMember.vue"),
      },
      {
        path: "superadmin/reporting",
        name: "Reporting",
        component: () => import("@/views/private/supervisor/Reporting.vue"),
      },
      {
        path: "superadmin/powerbiDashboard",
        name: "PowerBiDashboard",
        component: () => import("@/views/private/supervisor/PowerBi.vue"),
      }
    ],
  },

  // Catch-all route
  {
    path: "/:catchAll(.*)",
    redirect: { name: "LogIn" }, // Redirect to LogIn if no route matches
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
